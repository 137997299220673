import { Web3Provider } from "@ethersproject/providers";
import { walletConnectV2 } from "../connectors/walletConnectV2";
import { metaMask } from "../connectors/metaMask";
import { Web3ReactProvider } from "@web3-react/core";
import React from 'react';

const ConnectorNames = {
    Injected: "injected",
    WalletConnect: "walletconnect",
};

export const connectorsByName = {
    [ConnectorNames.Injected]: metaMask,
    [ConnectorNames.WalletConnect]: walletConnectV2,
};

export const getLibraryForSign = (provider) => {
    const library = new Web3Provider(provider);
    console.log("Web3Provider for signing:", library);
    return library;
};

export const getLibrary = (provider) => {
    const library = new Web3Provider(provider);
    console.log("General Web3Provider:", library);
    return library;
};

const MyApp = () => {
    return (
        <Web3ReactProvider getLibrary={getLibrary}>
            <div>Your DApp is ready!</div>
        </Web3ReactProvider>
    );
};

export default MyApp;
