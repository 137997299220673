import { toast } from 'react-toastify';

const customErrorToastStyle = {
    backgroundColor: 'black',
    border: '1px solid #F00',
    color: 'red',
};

const customWarningToastStyle = {
    backgroundColor: 'black',
    border: '1px solid #FF7600',
    color: '#FF7600',
};
const customSuccessToastStyle = {
    backgroundColor: 'black',
    border: '1px solid #8CC63E',
    color: '#8CC63E',
};
export const showSuccessToast = (message) => {
    toast.success(
        <div style={{ display: 'flex', alignItems: 'center', gap: "10px" }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                <path d="M24.818 8.27273V17.7273H23.6362V20.0909H22.4544V21.2727H21.2726V22.4546H20.0907V23.6364H17.7271V24.8182H8.27255V23.6364H5.90891V22.4546H4.7271V21.2727H3.54528V20.0909H2.36346V17.7273H1.18164V8.27273H2.36346V5.9091H3.54528V4.72728H4.7271V3.54546H5.90891V2.36364H8.27255V1.18182H17.7271V2.36364H20.0907V3.54546H21.2726V4.72728H22.4544V5.9091H23.6362V8.27273H24.818ZM20.0907 7.09092V5.9091H18.9089V4.72728H16.5453V3.54546H9.45437V4.72728H7.09073V5.9091H5.90891V7.09092H4.7271V9.45455H3.54528V16.5455H4.7271V18.9091H5.90891V20.0909H7.09073V21.2727H9.45437V22.4546H16.5453V21.2727H18.9089V20.0909H20.0907V18.9091H21.2726V16.5455H22.4544V9.45455H21.2726V7.09092H20.0907Z" fill="#8CC63E" />
                <path d="M17.1538 9H18.0769V9.88889H17.1538V9Z" fill="#8CC63E" />
                <path d="M16.2308 9.88889H17.1538V10.7778H16.2308V9.88889Z" fill="#8CC63E" />
                <path d="M18.0769 9.88889H19V10.7778H18.0769V9.88889Z" fill="#8CC63E" />
                <path d="M17.1538 9.88889H18.0769V10.7778H17.1538V9.88889Z" fill="#8CC63E" />
                <path d="M17.1538 10.7778H18.0769V11.6667H17.1538V10.7778Z" fill="#8CC63E" />
                <path d="M15.3077 10.7778H16.2308V11.6667H15.3077V10.7778Z" fill="#8CC63E" />
                <path d="M14.3846 11.6667H15.3077V12.5556H14.3846V11.6667Z" fill="#8CC63E" />
                <path d="M16.2308 11.6667H17.1538V12.5556H16.2308V11.6667Z" fill="#8CC63E" />
                <path d="M15.3077 11.6667H16.2308V12.5556H15.3077V11.6667Z" fill="#8CC63E" />
                <path d="M15.3077 12.5556H16.2308V13.4444H15.3077V12.5556Z" fill="#8CC63E" />
                <path d="M13.4615 12.5556H14.3846V13.4444H13.4615V12.5556Z" fill="#8CC63E" />
                <path d="M12.5385 13.4444H13.4615V14.3333H12.5385V13.4444Z" fill="#8CC63E" />
                <path d="M14.3846 13.4444H15.3077V14.3333H14.3846V13.4444Z" fill="#8CC63E" />
                <path d="M13.4615 13.4444H14.3846V14.3333H13.4615V13.4444Z" fill="#8CC63E" />
                <path d="M13.4615 14.3333H14.3846V15.2222H13.4615V14.3333Z" fill="#8CC63E" />
                <path d="M11.6154 14.3333H12.5385V15.2222H11.6154V14.3333Z" fill="#8CC63E" />
                <path d="M10.6923 15.2222H11.6154V16.1111H10.6923V15.2222Z" fill="#8CC63E" />
                <path d="M12.5385 15.2222H13.4615V16.1111H12.5385V15.2222Z" fill="#8CC63E" />
                <path d="M11.6154 15.2222H12.5385V16.1111H11.6154V15.2222Z" fill="#8CC63E" />
                <path d="M11.6154 16.1111H12.5385V17H11.6154V16.1111Z" fill="#8CC63E" />
                <path d="M9.76923 12.5556H10.6923V13.4444H9.76923V12.5556Z" fill="#8CC63E" />
                <path d="M8.84615 13.4444H9.76923V14.3333H8.84615V13.4444Z" fill="#8CC63E" />
                <path d="M10.6923 13.4444H11.6154V14.3333H10.6923V13.4444Z" fill="#8CC63E" />
                <path d="M9.76923 13.4444H10.6923V14.3333H9.76923V13.4444Z" fill="#8CC63E" />
                <path d="M9.76923 14.3333H10.6923V15.2222H9.76923V14.3333Z" fill="#8CC63E" />
                <path d="M7.92308 10.7778H8.84615V11.6667H7.92308V10.7778Z" fill="#8CC63E" />
                <path d="M7 11.6667H7.92308V12.5556H7V11.6667Z" fill="#8CC63E" />
                <path d="M8.84615 11.6667H9.76923V12.5556H8.84615V11.6667Z" fill="#8CC63E" />
                <path d="M7.92308 11.6667H8.84615V12.5556H7.92308V11.6667Z" fill="#8CC63E" />
                <path d="M7.92308 12.5556H8.84615V13.4444H7.92308V12.5556Z" fill="#8CC63E" />
                <path d="M16.2308 10.7778H17.1538V11.6667H16.2308V10.7778Z" fill="#8CC63E" />
                <path d="M14.3846 12.5556H15.3077V13.4444H14.3846V12.5556Z" fill="#8CC63E" />
                <path d="M12.5385 14.3333H13.4615V15.2222H12.5385V14.3333Z" fill="#8CC63E" />
                <path d="M10.6923 14.3333H11.6154V15.2222H10.6923V14.3333Z" fill="#8CC63E" />
                <path d="M8.84615 12.5556H9.76923V13.4444H8.84615V12.5556Z" fill="#8CC63E" />
            </svg>
            <span>{message}</span>
        </div>,
        {
            style: customSuccessToastStyle,
        }
    );
};
export const showErrorToast = (message) => {
    toast.error(
        <div style={{ display: 'flex', alignItems: 'center', gap: "10px" }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                <path d="M24.818 8.27273V17.7273H23.6362V20.0909H22.4544V21.2727H21.2726V22.4546H20.0907V23.6364H17.7271V24.8182H8.27255V23.6364H5.90891V22.4546H4.7271V21.2727H3.54528V20.0909H2.36346V17.7273H1.18164V8.27273H2.36346V5.9091H3.54528V4.72728H4.7271V3.54546H5.90891V2.36364H8.27255V1.18182H17.7271V2.36364H20.0907V3.54546H21.2726V4.72728H22.4544V5.9091H23.6362V8.27273H24.818ZM20.0907 7.09091V5.9091H18.9089V4.72728H16.5453V3.54546H9.45437V4.72728H7.09073V5.9091H5.90891V7.09091H4.7271V9.45455H3.54528V16.5455H4.7271V18.9091H5.90891V20.0909H7.09073V21.2727H9.45437V22.4546H16.5453V21.2727H18.9089V20.0909H20.0907V18.9091H21.2726V16.5455H22.4544V9.45455H21.2726V7.09091H20.0907ZM16.5453 8.27273V9.45455H17.7271V10.6364H16.5453V11.8182H15.3635V14.1818H16.5453V15.3636H17.7271V16.5455H16.5453V17.7273H15.3635V16.5455H14.1816V15.3636H11.818V16.5455H10.6362V17.7273H9.45437V16.5455H8.27255V15.3636H9.45437V14.1818H10.6362V11.8182H9.45437V10.6364H8.27255V9.45455H9.45437V8.27273H10.6362V9.45455H11.818V10.6364H14.1816V9.45455H15.3635V8.27273H16.5453Z" fill="#FF0000" />
            </svg>
            <span>{message}</span>
        </div>,
        {
            style: customErrorToastStyle,
        }
    );
};

export const showWarningToast = (message) => {
    toast.warning(
        <div style={{ display: 'flex', alignItems: 'center', gap: "10px" }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                <path d="M14.1816 14.1818H11.818V7.09091H14.1816V14.1818ZM14.1816 18.9091H11.818V16.5455H14.1816V18.9091ZM17.7271 24.8182H8.27255V23.6364H5.90891V22.4545H4.7271V21.2727H3.54528V20.0909H2.36346V17.7273H1.18164V8.27273H2.36346V5.9091H3.54528V4.72728H4.7271V3.54546H5.90891V2.36364H8.27255V1.18182H17.7271V2.36364H20.0907V3.54546H21.2725V4.72728H22.4544V5.9091H23.6362V8.27273H24.818V17.7273H23.6362V20.0909H22.4544V21.2727H21.2725V22.4545H20.0907V23.6364H17.7271V24.8182ZM16.5453 22.4545V21.2727H18.9089V20.0909H20.0907V18.9091H21.2725V16.5455H22.4544V9.45455H21.2725V7.09091H20.0907V5.9091H18.9089V4.72728H16.5453V3.54546H9.45437V4.72728H7.09073V5.9091H5.90891V7.09091H4.7271V9.45455H3.54528V16.5455H4.7271V18.9091H5.90891V20.0909H7.09073V21.2727H9.45437V22.4545H16.5453Z" fill="#FF7600" />
            </svg>
            <span>{message}</span>
        </div>,
        {
            style: customWarningToastStyle,
        }
    );
};
