import React from 'react'
import "./congrats.scss"
import { Link } from 'react-router-dom'

const Congrats = () => {
    return (
        <>
            <section className="congrats-section">
                <div className="custom-container">
                    <div className="main-img">
                        <img width={296} src="\assets\congrats\congrats-icon.png" alt="img" className='img-fluid' />
                    </div>
                    <div className="bottom-content">
                        <h5>Congratulations</h5>
                        <p>Your score has been successfully added to the leaderboard</p>
                        <Link to="/leaderboard" className='btn-view'>VIEW leaderboard</Link>
                    </div>
                    <div className="bottom-logo">
                        <img src="\large-logo.svg" alt="img" className="img-fluid" />
                        <h4>Your data, your choice</h4>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Congrats
