// import logo from './logo.svg';
import '../../App.scss';
import React, { useState, useEffect } from "react";
import Banner from './main-banner/Banner.js';
import Navbar from './header/Navbar';
import AfterBanner from './main-banner/AfterBanner.js';



function Landing() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <>
      <div className="banner-wrapper-landing">
        <Navbar />
        <Banner />
      </div>
      {/* <AfterBanner /> */}
    </>
  );
}

export default Landing;