import './App.scss';
import Landing from './components/landing/Landing.js';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import How from './components/How/How.js';
import Points from './components/Points/Points.js';
import AfterBanner from './components/landing/main-banner/AfterBanner.js';
import Login from './components/Login/Login.js';
import { Web3ReactProvider } from '@web3-react/core';
import { hooks as walletConnectV2Hooks, walletConnectV2 } from './connectors/walletConnectV2.js';
import { hooks as metaMaskHooks, metaMask } from './connectors/metaMask.js'; // Import metaMask and its hooks
import { Provider } from 'react-redux';
import store from './redux/store/store'
// import web3P
import { Web3Provider } from "@ethersproject/providers";
import Congrats from './components/Congrats/Congrats.js';

const connectors = [
  [walletConnectV2, walletConnectV2Hooks],
  [metaMask, metaMaskHooks]
];

function getLibrary(provider) {
  return new Web3Provider(provider);
}

function App() {
 
  return (
    <Web3ReactProvider connectors={connectors} getLibrary={getLibrary}>
      <Provider store={store}>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        style={{ fontSize: '16px', zIndex: "9999999999999999" }}
        draggable
        pauseOnHover
        theme="colored"
      />
        <Router>
          <Switch>
            <Route exact path='/home' component={Landing} />
            <Route exact path='/leaderboard' component={AfterBanner} />
            <Route exact path='/check' component={How} />
            <Route exact path='/share' component={Points} />
            <Route exact path='/' component={Login} />
            <Route exact path='/congrats' component={Congrats} />
          </Switch>
        </Router>
      </Provider>
    </Web3ReactProvider>
  );
}

export default App;
