
import { useState, useEffect } from "react";
import "./banner.scss";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { api_url } from "../../../utils/Enviroment";
import { useHistory } from 'react-router-dom';
import { showErrorToast } from "../../How/toastUtils";

const Banner = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [users, setUsers] = useState([]);
  const history = useHistory()

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  const getLeaderBoard = async () => {
    try {
      const token = localStorage.getItem('accessToken');
      const response = await axios.get(`${api_url}/users?limit=10&offset=1`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setUsers(response?.data?.data?.users || []);
      console.log(response?.data?.data, 'Data fetched');
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response && error.response.status === 401) {
        try {
          const refreshToken = localStorage.getItem('refreshToken');
          const refreshResponse = await axios.post(`${api_url}/auth/refresh-token`, { refreshToken: refreshToken });
          const newAccessToken = refreshResponse?.data?.data?.accessToken;
          if (newAccessToken) {
            localStorage.setItem('accessToken', newAccessToken);
            const retryResponse = await axios.get(`${api_url}/users?limit=10&offset=1`, {
              headers: {
                Authorization: `Bearer ${newAccessToken}`
              }
            });
            setUsers(retryResponse?.data?.data?.users || []);
            console.log(retryResponse?.data?.data, 'Data fetched after token refresh');
          }
        } catch (refreshError) {
          console.error("Error refreshing token:", refreshError);
          localStorage.removeItem('savedPassword');
          localStorage.removeItem('savedUser');
          localStorage.removeItem('remember');
          localStorage.removeItem('actionPayload');
          history.push('/');
          showErrorToast("Session expired. Please log in again.");
        }
      } else {
        console.error("Error fetching data:", error);
      }
    }
  };

  // const getLeaderBoard = async () => {
  //   try {
  //     const token = localStorage.getItem('accessToken');

  //     const response = await axios.get(`${api_url}/users?&limit=10&offset=1`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`
  //       }
  //     });
  //     setUsers(response?.data?.data?.users || []);
  //     console.log(response?.data?.data, 'Data fetched');
  //   } catch (error) {
  //     console.error(error);
  //     if (error.response && error.response.status === 401) {
  //       try {
  //         const refreshToken = localStorage.getItem('refreshToken');
  //         const refreshResponse = await axios.post(`${api_url}/auth/refresh-token`, { refreshToken: refreshToken });
  //         const newAccessToken = refreshResponse?.data?.data?.accessToken;
  //         localStorage.setItem('accessToken', newAccessToken);
  //         const retryResponse = await axios.get(`${api_url}/users?limit=100&offset=${currPage}`, {
  //           headers: {
  //             Authorization: `Bearer ${newAccessToken}`
  //           }
  //         });
  //         setUsers(prevUserList => [...prevUserList, ...retryResponse.data.data.users]);
  //       } catch (refreshError) {
  //         console.error("Error refreshing token:", refreshError);
  //         localStorage.removeItem('savedPassword')
  //         localStorage.removeItem('savedUser')
  //         // history.push('/');
  //       }
  //     } else {
  //       console.error("Error fetching data:", error);
  //     }
  //   }
  // };

  useEffect(() => {
    getLeaderBoard();
  }, []);


  return (
    <>
      <section className="main-banner">
        <img src="\assets\banner\banner-bg.png" alt="img" className="img-fluid banner-bg" />
        <div className="custom-container">
          <div className="main-content">
            <h4>The data ownership checklist</h4>
            <p>May the least transparent user win!</p>
            <div className="bottom-twice">
              <div className="single-card">
                <img src="\assets\banner\check.svg" alt="img" className="img-fluid" />
                <h6>check in boxes</h6>
              </div>
              <span><img src="\assets\banner\right-arrow.svg" alt="img" className="img-fluid" /></span>
              <div className="single-card">
                <img src="\assets\banner\share.svg" alt="img" className="img-fluid" />
                <h6>Share your score</h6>
              </div>
              <span><img src="\assets\banner\right-arrow.svg" alt="img" className="img-fluid" /></span>
              <div className="single-card">
                <img src="\assets\banner\gift.svg" alt="img" className="img-fluid" />
                <h6>Win A prize</h6>
              </div>
            </div>
          </div>
          <div className="leaderboard">
            <div className="main-heading">
              <h4>LEADERBOARD</h4>
            </div>
            <div className="parent">
              {/* <span className="blurryeffect"><h6>Top 3 winners coming soon! <br /> Stay tuned!</h6></span> */}
              {/* <div className="single-item transform-minus">
                <div className="main-img">
                  <img src="\assets\emojis\2.png" alt="img" className="img-fluid main-emoji" />
                  <img src="\assets\banner\two.svg" alt="img" className="img-fluid common-center-img" />
                </div>
                <div className="text">
                  <h6>Barbara</h6>
                  <p>Score: 3457</p>
                </div>
              </div> */}

              <div className="single-item transform-minus">
                <div className="main-img">
                  <img
                    src={users[1]?.icon || `/assets/emojis/2.png`}
                    alt="img"
                    className="img-fluid main-emoji"
                  />
                  <img
                    src="/assets/banner/two.svg"
                    alt="img"
                    className="img-fluid common-center-img"
                  />
                </div>
                <div className="text">
                  <h6>{users[1]?.name}</h6>
                  <p>Score: {users[1]?.points}</p>
                </div>
              </div>
              {/* <div className="single-item center-main">
                <div className="main-img">
                  <img src="\assets\emojis\1.png" alt="img" className="img-fluid main-emoji" />
                  <img src="\assets\banner\one.svg" alt="img" className="img-fluid common-center-img" />
                </div>
                <div className="text">
                  <h6>Barbara</h6>
                  <p>Score: 3457</p>
                </div>
              </div> */}
              <div className="single-item center-main">
                <div className="main-img">
                  <img
                    src={users[0]?.icon || `/assets/emojis/1.png`}
                    alt="img"
                    className="img-fluid main-emoji"
                  />
                  <img
                    src="/assets/banner/one.svg"
                    alt="img"
                    className="img-fluid common-center-img"
                  />
                </div>
                <div className="text">
                  <h6>{users[0]?.name}</h6>
                  <p>Score: {users[0]?.points}</p>
                </div>
              </div>
              {/* <div className="single-item transform-minus">
                <div className="main-img">
                  <img src="\assets\emojis\3.png" alt="img" className="img-fluid main-emoji" />
                  <img src="\assets\banner\three.svg" alt="img" className="img-fluid common-center-img" />
                </div>
                <div className="text">
                  <h6>Barbara</h6>
                  <p>Score: 3457</p>
                </div>
              </div> */}
              <div className="single-item transform-minus">
                <div className="main-img">
                  <img
                    src={users[2]?.icon || `/assets/emojis/3.png`}
                    alt="img"
                    className="img-fluid main-emoji"
                  />
                  <img
                    src="/assets/banner/three.svg"
                    alt="img"
                    className="img-fluid common-center-img"
                  />
                </div>
                <div className="text">
                  <h6>{users[2]?.name}</h6>
                  <p>Score: {users[2]?.points}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="custom-checkbox">
            <div className="form-group">
              <input
                type="checkbox"
                id="first"
                onChange={handleCheckboxChange}
              />
              <label htmlFor="first"></label>
            </div>
            <label htmlFor="first">I agree to the <a href="\termofuse.pdf" target="_blank">Terms of Use</a></label>
          </div>
          <Link to="/check">
            <div className="bottom-btn">
              <button
                disabled={!isChecked}
                style={{
                  opacity: !isChecked ? 0.5 : 1,
                  cursor: !isChecked ? 'not-allowed' : 'pointer'
                }}
              >
                Start the Game
              </button>
            </div>
          </Link>
        </div>
      </section>
    </>
  );
};

export default Banner;
