import React, { useEffect, useState } from 'react'
import "./how.scss"
import Navbar from '../landing/header/Navbar'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { api_url } from '../../utils/Enviroment'
// import { useNavigate } from 'react-router-dom'
import { useHistory } from 'react-router-dom';
import { showErrorToast } from './toastUtils'
import { toast } from 'react-toastify';

const How = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        const hasReloaded = sessionStorage.getItem('hasReloaded');
        if (!hasReloaded) {

            window.location.reload();
            sessionStorage.setItem('hasReloaded', 'true');
        }
    }, []);



    const [checkedActions, setCheckedActions] = useState([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const history = useHistory();

    const handleCheckboxChange = (e) => {
        const { id, checked } = e.target;
        const actionText = document.querySelector(`label[for='${id}'] h6`)?.textContent || '';
        setCheckedActions(prevCheckedActions => {
            const updatedCheckedActions = checked
                ? [...prevCheckedActions, actionText]
                : prevCheckedActions.filter(action => action !== actionText);
            setIsButtonDisabled(updatedCheckedActions.length === 0);

            return updatedCheckedActions;
        });
    };
    const CheckActions = () => {
        if (checkedActions.length === 0) {
            showErrorToast("You must select at least one action");
            return;
        }

        const token = localStorage.getItem('accessToken');
        const data = {
            actions: checkedActions
        };

        const makeApiCall = async (accessToken) => {
            const config = {
                method: "post",
                url: `${api_url}/actions/action-points`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                data: JSON.stringify(data),
            };
            return axios(config);
        };

        const refreshToken = async () => {
            try {
                const refreshToken = localStorage.getItem('refreshToken');
                const response = await axios.post(`${api_url}/auth/refresh-token`, { refreshToken: refreshToken });
                const newAccessToken = response.data.data.accessToken;
                localStorage.setItem('accessToken', newAccessToken);
                return newAccessToken;
            } catch (error) {
                console.error("Error refreshing token:", error);
                localStorage.removeItem('savedPassword');
                localStorage.removeItem('savedUser');
                localStorage.removeItem('remember');
                localStorage.removeItem('actionPayload');
                showErrorToast("Session expired. Please log in again.");
                history.push('/');
                throw error;
            }
        };

        const attemptRequest = async () => {
            try {
                let response = await makeApiCall(token);
                history.push({
                    pathname: '/share',
                    state: {
                        points: response.data.data.points,
                        rank: response.data.data.rank,
                        notFoundActions: response.data.data.notFoundActions
                    }
                });
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    try {
                        const newAccessToken = await refreshToken();
                        const response = await makeApiCall(newAccessToken);
                        history.push({
                            pathname: '/share',
                            state: {
                                points: response.data.data.points,
                                rank: response.data.data.rank,
                                notFoundActions: response.data.data.notFoundActions
                            }
                        });
                    } catch (refreshError) {
                        console.error("Error fetching data:", refreshError);
                    }
                } else {
                    console.error("Error fetching data:", error);
                }
            }
        };

        localStorage.setItem('actionPayload', JSON.stringify(data));
        attemptRequest();
    };


    // const CheckActions = () => {
    //     if (checkedActions.length === 0) {
    //         showErrorToast("⁠You must select at least one action");
    //         return;
    //     }
    //     const token = localStorage.getItem('accessToken');
    //     const data = {
    //         actions: checkedActions
    //     };
    //     localStorage.setItem('actionPayload', JSON.stringify(data));
    //     var config = {
    //         method: "post",
    //         url: `${api_url}/actions/action-points`,
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Authorization": `Bearer ${token}`
    //         },
    //         data: JSON.stringify(data),
    //     };
    //     axios(config)
    //         .then(function (response) {
    //             history.push({
    //                 pathname: '/share',
    //                 state: {
    //                     points: response.data.data.points,
    //                     rank: response.data.data.rank,
    //                     notFoundActions: response.data.data.notFoundActions
    //                 }
    //             });
    //         })
    //         .catch(function (error) {
    //             // if (error.response && error.response.status === 401) {
    //             //     showErrorToast("Session expired. Please log in again.");
    //             //     localStorage.removeItem("actionPayload");
    //             //     history.push('/');
    //             // } else {
    //             //     console.error("There was an error!", error);
    //             // }
    //             if (error.response && error.response.status === 401) {
    //                 try {
    //                   const refreshToken = localStorage.getItem('refreshToken');
    //                   const refreshResponse =  axios.post(`${api_url}/auth/refresh-token`, { refreshToken: refreshToken });
    //                   const newAccessToken = refreshResponse?.data?.data?.accessToken;
    //                   localStorage.setItem('accessToken', newAccessToken);
    //                 } catch (refreshError) {
    //                   console.error("Error refreshing token:", refreshError);
    //                   localStorage.removeItem('savedPassword');
    //                   localStorage.removeItem('savedUser');
    //                   history.push('/');
    //                 }
    //               } else {
    //                 console.error("Error fetching data:", error);
    //               }
    //         });
    // };


    return (
        <>
            <Navbar />
            <section className="how-section">
                <div className="custom-container">
                    <div className="main-heading">
                        <h4>Check all the actions you’ve <br /> performed this month</h4>
                    </div>
                    <div className="bottom-content">
                        <div className="inside-parent">
                            <label htmlFor='first' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\1.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Transferred assets within DOP</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="first"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="first"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>

                            <label htmlFor='sec' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\2.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Swapped crypto on a centralized exchange</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="sec"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="sec"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='three' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\3.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Swapped crypto on a decentralized exchange</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="three"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="three"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='four' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\4.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Transferred funds between wallets on chain</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="four"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="four"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='five' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\5.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Integrated your wallet with a Discord bot</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="five"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="five"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='six' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\66.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Completed a KYC</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="six"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="six"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='seven' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\77.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Completed a ZK KYC</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="seven"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="seven"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='eight' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\6.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Used a crypto credit card</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="eight"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="eight"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='nine' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\7.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Shared an image of an NFT on social media</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="nine"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="nine"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='ten' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\8.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Shared an image of an NFT in DOP wallet on social media</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="ten"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="ten"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='eleven' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\9.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Used a privacy coin for transactions   </h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="eleven"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="eleven"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='twelve' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\10.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Linked an ENS (Ethereum Name Service)</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="twelve"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="twelve"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='thirteen' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\11.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Updated wallet software</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="thirteen"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="thirteen"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='fourteen' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\12.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Generated a new wallet address for receiving funds</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="fourteen"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="fourteen"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='fifteen' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\13.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Sent crypto to a colleague from my main wallet</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="fifteen"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="fifteen"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='sixteen' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\14.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Received crypto from a colleague to my main wallet</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="sixteen"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="sixteen"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='seventeen' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\15.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Minted an NFT</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="seventeen"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="seventeen"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='eighteen' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\16.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Bought an NFT from a friend</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="eighteen"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="eighteen"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='nineteen' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\17.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Bought an NFT from a friend on DOP</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="nineteen"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="nineteen"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='twenty' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\18.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Transferred an NFT to another wallet</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="twenty"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="twenty"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='twentyone' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\19.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Staked tokens on a smart contract (DeFi)</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="twentyone"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="twentyone"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='twentytwo' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\20.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Used a new dApp</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="twentytwo"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="twentytwo"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='twentythree' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\21.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Withdrew funds from an exchange</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="twentythree"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="twentythree"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='twnetyfour' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\22.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Converted fiat to crypto with a credit card</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="twnetyfour"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="twnetyfour"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='twnetyfive' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\23.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Converted crypto to fiat and sent it to bank account</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="twnetyfive"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="twnetyfive"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='twnetysix' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\24.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Participated in an airdrop by sharing info</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="twnetysix"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="twnetysix"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='twnetyseven' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\25.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Joined a telegram community with an anonymous number</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="twnetyseven"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="twnetyseven"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='twnetyeight' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\26.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Provided liquidity in a DEX liquidity pool</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="twnetyeight"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="twnetyeight"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='twnetynine' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\277.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Engaged in liquidity mining</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="twnetynine"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="twnetynine"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='thirty' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\288.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Sent cross-border payments using crypto</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="thirty"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="thirty"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>


                            {/* <label htmlFor='thirtyone' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\27.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Transferred funds to an exchange</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="thirtyone"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="thirtyone"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='thirtytwo' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\28.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Withdrew funds from an exchange</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="thirtytwo"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="thirtytwo"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='thirtythree' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\29.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Set up a crypto alert</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="thirtythree"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="thirtythree"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='thirtyfour' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\30.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Calculated potential gains/losses</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="thirtyfour"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="thirtyfour"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='thirtyfive' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\31.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Converted crypto to fiat</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="thirtyfive"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="thirtyfive"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='thirtysix' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\32.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Converted fiat to crypto</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="thirtysix"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="thirtysix"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='thirtysseven' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\33.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Donated crypto to a cause</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="thirtysseven"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="thirtysseven"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='thirtyseight' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\34.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Participated in a crypto airdrop</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="thirtyseight"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="thirtyseight"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='thirtysnine' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\35.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Joined a crypto community group</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="thirtysnine"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="thirtysnine"></label>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor='forty' className="single-item">
                                <div className="left-side">
                                    <img src="\assets\how\36.png" alt="img" className='img-fluid' width={28} height={28} />
                                    <h6>Attended a crypto webinar</h6>
                                </div>
                                <div className="right-side">
                                    <div className="custom-checkbox">
                                        <div className="form-group">
                                            <input
                                                type="checkbox"
                                                id="forty"
                                                onChange={handleCheckboxChange}
                                            />
                                            <label htmlFor="forty"></label>
                                        </div>
                                    </div>
                                </div>
                            </label> */}
                        </div>
                    </div>
                    {/* <Link to="/share" > */}
                    <div className="bottom-btn">
                        <button
                            style={{
                                opacity: isButtonDisabled ? 0.5 : 1,
                                cursor: isButtonDisabled ? 'not-allowed' : 'pointer'
                            }}
                            onClick={() => CheckActions()}
                        >HOW DID I DO?</button>
                    </div>
                    {/* </Link> */}
                </div>
            </section>
        </>
    )
}

export default How
