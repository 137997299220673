import React, { useEffect, useRef, useState } from 'react'
import "./points.scss"
import Navbar from '../landing/header/Navbar'
import { Modal } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { api_url } from '../../utils/Enviroment'
import { toast } from 'react-toastify'
import Web3 from 'web3'
import { useWeb3React } from '@web3-react/core'
import useAuth from '../../hooks/useAuth'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom';
import { showWarningToast, showSuccessToast, showErrorToast } from '../How/toastUtils'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';



const Points = () => {
    const [show, setShow] = useState(false);
    let { account } = useWeb3React();
    console.log(account, "account");
    const location = useLocation();
    const [selectedIcon, setSelectedIcon] = useState(null);
    const [name, setName] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const { login, logout } = useAuth();
    const [isSubmitting, setIsSubmitting] = useState(false);


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { points, rank } = location.state || { points: 0, notFoundActions: [] };

    const history = useHistory();

    const handleIconChange = (e) => {
        setSelectedIcon(e.target.id);
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const trustWallet = async (e) => {
        try {
            if (account) {
                await logout("walletconnect");
            } else {
                login("walletconnect", e);
                localStorage.setItem("connectorId", "walletconnect");
                localStorage.setItem("flag", "true");
            }
        } catch (error) {
            console.error("Error during WalletConnect operation:", error);
            toast.error("An error occurred during WalletConnect operation");
        }
    };

    // const disconnectWallet = async () => {
    //     const connectorId = window.localStorage.getItem("connectorId");
    //     logout(connectorId);
    //     localStorage.removeItem("connectorId");
    //     localStorage.removeItem("flag");
    //     localStorage.removeItem("chain");
    // };
    const disconnectWallet = async () => {
        const connectorId = window.localStorage.getItem("connectorId");

        if (connectorId) {
            try {
                // Assuming logout is an async function that disconnects the wallet
                await logout(connectorId);
                console.log("Disconnected successfully");
            } catch (error) {
                console.error("Error during wallet disconnection:", error);
            }
        }
        localStorage.removeItem("connectorId");
        localStorage.removeItem("flag");
        localStorage.removeItem("chain");
        localStorage.removeItem("WCM_VERSION");

    };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     if (isSubmitting) return;
    //     setIsSubmitting(true);
    //     const actions = JSON.parse(localStorage.getItem('actionPayload'))?.actions || [];
    //     if (name.length === 0) {
    //         showWarningToast("How should we call you?");
    //         setIsSubmitting(false);
    //         return;
    //     } else if (!selectedIcon) {
    //         showWarningToast("⁠Pick your icon");
    //         setIsSubmitting(false);
    //         return;
    //     } else if (!account) {
    //         showWarningToast("⁠Connect your wallet to win a prize");
    //         setIsSubmitting(false);
    //         return;
    //     } else {
    //         const payload = {
    //             walletAddress: account,
    //             icon: `assets/emojis/${selectedIcon}.png`,
    //             name: name,
    //             actions: actions
    //         };
    //         axios.post(`${api_url}/users`, payload, {
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
    //             }
    //         })
    //             .then(response => {
    //                 handleClose();
    //                 setName("");
    //                 setSelectedIcon(null);
    //                 localStorage.removeItem("WCM_VERSION");
    //                 disconnectWallet();
    //                 const cacheBuster = new Date().getTime();
    //                 const newUrl = `congrats?cacheBuster=${cacheBuster}`;
    //                 history.push(newUrl);
    //             })
    //             .catch(error => {
    //                 showErrorToast(error?.response?.data?.message);
    //                 console.error(error);
    //             })
    //             .finally(() => {
    //                 setIsSubmitting(false);
    //             });
    //     }
    // };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isSubmitting) return;
        setIsSubmitting(true);

        const actions = JSON.parse(localStorage.getItem('actionPayload'))?.actions || [];

        if (name.length === 0) {
            showWarningToast("How should we call you?");
            setIsSubmitting(false);
            return;
        } else if (!selectedIcon) {
            showWarningToast("⁠Pick your icon");
            setIsSubmitting(false);
            return;
        } else if (!account) {
            showWarningToast("⁠Connect your wallet to win a prize");
            setIsSubmitting(false);
            return;
        }

        const payload = {
            walletAddress: account,
            icon: `assets/emojis/${selectedIcon}.png`,
            name: name,
            actions: actions
        };

        const postData = async (accessToken) => {
            try {
                await axios.post(`${api_url}/users`, payload, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${accessToken}`
                    }
                });
                handleClose();
                setName("");
                setSelectedIcon(null);
                localStorage.removeItem("WCM_VERSION");
                disconnectWallet();
                const cacheBuster = new Date().getTime();
                const newUrl = `congrats?cacheBuster=${cacheBuster}`;
                history.push(newUrl);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    try {
                        const newAccessToken = await refreshToken();
                        if (newAccessToken) {
                            await postData(newAccessToken);
                        } else {
                            handleLogout();
                        }
                    } catch (refreshError) {
                        handleLogout();
                    }
                } else {
                    showErrorToast(error?.response?.data?.message || "An error occurred.");
                    disconnectWallet();
                    console.error("Error submitting data:", error);
                }
            }
        };

        const refreshToken = async () => {
            try {
                const refreshToken = localStorage.getItem('refreshToken');
                const response = await axios.post(`${api_url}/auth/refresh-token`, { refreshToken: refreshToken });
                const newAccessToken = response.data.data.accessToken;
                localStorage.setItem('accessToken', newAccessToken);
                return newAccessToken;
            } catch (error) {
                console.error("Error refreshing token:", error);
                localStorage.removeItem('savedPassword');
                localStorage.removeItem('savedUser');
                localStorage.removeItem('remember');
                localStorage.removeItem('actionPayload');
                showErrorToast("Session expired. Please log in again.");
                return null;
            }
        };
        const handleLogout = () => {
            disconnectWallet();
            history.push('/');
            setIsSubmitting(false);
        };
        const currentAccessToken = localStorage.getItem('accessToken');
        await postData(currentAccessToken);
        setIsSubmitting(false);
    };


    const containerRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [canScrollPrev, setCanScrollPrev] = useState(false);
    const [canScrollNext, setCanScrollNext] = useState(true);
    const onMouseDown = (e) => {
        if (containerRef.current) {
            setIsDragging(true);
            setStartX(e.pageX - containerRef.current.offsetLeft);
            setScrollLeft(containerRef.current.scrollLeft);
        }
    };

    const onMouseLeave = () => {
        setIsDragging(false);
    };

    const onMouseUp = () => {
        setIsDragging(false);
    };

    const onMouseMove = (e) => {
        if (!isDragging || !containerRef.current) return;
        e.preventDefault();
        const x = e.pageX - containerRef.current.offsetLeft;
        const walk = (x - startX) * 2;
        containerRef.current.scrollLeft = scrollLeft - walk;
    };

    const scrollNext = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({ left: 100, behavior: 'smooth' });
        }
    };

    const scrollPrev = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({ left: -100, behavior: 'smooth' });
        }
    };

    const updateButtonStates = () => {
        if (!containerRef.current) return;

        const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
        setCanScrollPrev(scrollLeft > 0);
        setCanScrollNext(scrollLeft < scrollWidth - clientWidth);
    };

    useEffect(() => {
        updateButtonStates();
        window.addEventListener('resize', updateButtonStates);
        return () => window.removeEventListener('resize', updateButtonStates);
    }, []);

    useEffect(() => {
        const container = containerRef.current;
        if (!container) return;

        container.addEventListener('scroll', updateButtonStates);
        updateButtonStates();

        return () => container.removeEventListener('scroll', updateButtonStates);
    }, []);



    const handledeletelocal = (() => {
        disconnectWallet();
        const cacheBuster = new Date().getTime();
        const newUrl = `home?cacheBuster=${cacheBuster}`;
        history.push(newUrl)
    })

    const [isDisabled, setIsDisabled] = useState(false);

    const handleClick = () => {
        setIsDisabled(true);
        setTimeout(() => {
            setIsDisabled(false);
        }, 5000);
    };

    const sliderRef = useRef(null);

    const settings = {
        infinite: true,
        dots: false,
        arrows: false,
        slidesToShow: 6.5,
        slidesToScroll: 3,
        autoplay: false,
        pauseOnHover: false,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3.2,
                    infinite: true,

                }
            }
        ]
    };


    return (
        <>
            <Navbar />
            <section className="points-section">
                <div className="custom-container">
                    <div className="main-content">
                        <img width={215} height={215} src="\assets\points\points-icon.png" alt="img" className='img-fluid' />
                        <h6>You scored</h6>
                        <h4>{points} Points!</h4>
                        <p>Currently placing you at</p>
                        <h5>No. {rank}</h5>

                        {/* <h5>
                            {notFoundActions.length === 0 ? (
                                <h5>No. 0</h5>
                            ) : (
                                notFoundActions.map((action, index) => (
                                    <li key={index}>{action}</li>
                                ))
                            )}
                        </h5> */}

                    </div>
                    <div className="bottom-btn">
                        <button onClick={handleShow}>DONE</button>
                    </div>
                </div>
            </section>

            <Modal
                className="common-modal"
                show={show}
                onHide={handleClose}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Put me on the leaderboard</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="inside-content">
                        <div className="option-field">
                            <p>Pick a name</p>
                            <input value={name}
                                onChange={handleNameChange} type="text" placeholder='Enter nickname' />
                        </div>
                        <div className="option-field">
                            <div className="twice-div">
                                <p className='mb-0'>Pick an Icon</p>
                                <div className="twice-btn">
                                    <button onClick={() => sliderRef.current.slickPrev()} className="scroll-button prev"><img src="\assets\congrats\prev-icon.png" alt="img" className='img-fluid' /></button>
                                    <button onClick={() => sliderRef.current.slickNext()} className="scroll-button next"><img src="\assets\congrats\next-button.png" alt="img" className='img-fluid' /></button>
                                </div>
                            </div>
                            <div className="pick-icons-container">
                                {/* <div className="pick-icons">
                                    <label htmlFor='first' className="single-pick">
                                        <img src="\assets\emojis\1.png" alt="img" className='img-fluid' />
                                        <div className="custom-checkbox">
                                            <div className="form-group">
                                                <input
                                                    type="checkbox"
                                                    id="first"
                                                />
                                                <label htmlFor="first"></label>
                                            </div>
                                        </div>
                                    </label>
                                    <label htmlFor='sec' className="single-pick">
                                        <img src="\assets\emojis\2.png" alt="img" className='img-fluid' />
                                        <div className="custom-checkbox">
                                            <div className="form-group">
                                                <input
                                                    type="checkbox"
                                                    id="sec"
                                                />
                                                <label htmlFor="sec"></label>
                                            </div>
                                        </div>
                                    </label>
                                    <label htmlFor='third' className="single-pick">
                                        <img src="\assets\emojis\3.png" alt="img" className='img-fluid' />
                                        <div className="custom-checkbox">
                                            <div className="form-group">
                                                <input
                                                    type="checkbox"
                                                    id="third"
                                                />
                                                <label htmlFor="third"></label>
                                            </div>
                                        </div>
                                    </label>
                                    <label htmlFor='four' className="single-pick">
                                        <img src="\assets\emojis\4.png" alt="img" className='img-fluid' />
                                        <div className="custom-checkbox">
                                            <div className="form-group">
                                                <input
                                                    type="checkbox"
                                                    id="four"
                                                />
                                                <label htmlFor="four"></label>
                                            </div>
                                        </div>
                                    </label>
                                    <label htmlFor='five' className="single-pick">
                                        <img src="\assets\emojis\5.png" alt="img" className='img-fluid' />
                                        <div className="custom-checkbox">
                                            <div className="form-group">
                                                <input
                                                    type="checkbox"
                                                    id="five"
                                                />
                                                <label htmlFor="five"></label>
                                            </div>
                                        </div>
                                    </label>
                                    <label htmlFor='six' className="single-pick">
                                        <img src="\assets\emojis\6.png" alt="img" className='img-fluid' />
                                        <div className="custom-checkbox">
                                            <div className="form-group">
                                                <input
                                                    type="checkbox"
                                                    id="six"
                                                />
                                                <label htmlFor="six"></label>
                                            </div>
                                        </div>
                                    </label>
                                    <label htmlFor='seven' className="single-pick">
                                        <img src="\assets\emojis\7.png" alt="img" className='img-fluid' />
                                        <div className="custom-checkbox">
                                            <div className="form-group">
                                                <input
                                                    type="checkbox"
                                                    id="seven"
                                                />
                                                <label htmlFor="seven"></label>
                                            </div>
                                        </div>
                                    </label>
                                    <label htmlFor='eight' className="single-pick">
                                        <img src="\assets\emojis\8.png" alt="img" className='img-fluid' />
                                        <div className="custom-checkbox">
                                            <div className="form-group">
                                                <input
                                                    type="checkbox"
                                                    id="eight"
                                                />
                                                <label htmlFor="eight"></label>
                                            </div>
                                        </div>
                                    </label>
                                    <label htmlFor='nine' className="single-pick">
                                        <img src="\assets\emojis\9.png" alt="img" className='img-fluid' />
                                        <div className="custom-checkbox">
                                            <div className="form-group">
                                                <input
                                                    type="checkbox"
                                                    id="nine"
                                                />
                                                <label htmlFor="nine"></label>
                                            </div>
                                        </div>
                                    </label>
                                    <label htmlFor='ten' className="single-pick">
                                        <img src="\assets\emojis\10.png" alt="img" className='img-fluid' />
                                        <div className="custom-checkbox">
                                            <div className="form-group">
                                                <input
                                                    type="checkbox"
                                                    id="ten"
                                                />
                                                <label htmlFor="ten"></label>
                                            </div>
                                        </div>
                                    </label>
                                    <label htmlFor='eleven' className="single-pick">
                                        <img src="\assets\emojis\11.png" alt="img" className='img-fluid' />
                                        <div className="custom-checkbox">
                                            <div className="form-group">
                                                <input
                                                    type="checkbox"
                                                    id="eleven"
                                                />
                                                <label htmlFor="eleven"></label>
                                            </div>
                                        </div>
                                    </label>
                                    <label htmlFor='twelve' className="single-pick">
                                        <img src="\assets\emojis\12.png" alt="img" className='img-fluid' />
                                        <div className="custom-checkbox">
                                            <div className="form-group">
                                                <input
                                                    type="checkbox"
                                                    id="twelve"
                                                />
                                                <label htmlFor="twelve"></label>
                                            </div>
                                        </div>
                                    </label>
                                    <label htmlFor='thirteen' className="single-pick">
                                        <img src="\assets\emojis\13.png" alt="img" className='img-fluid' />
                                        <div className="custom-checkbox">
                                            <div className="form-group">
                                                <input
                                                    type="checkbox"
                                                    id="thirteen"
                                                />
                                                <label htmlFor="thirteen"></label>
                                            </div>
                                        </div>
                                    </label>
                                    <label htmlFor='fourteen' className="single-pick">
                                        <img src="\assets\emojis\14.png" alt="img" className='img-fluid' />
                                        <div className="custom-checkbox">
                                            <div className="form-group">
                                                <input
                                                    type="checkbox"
                                                    id="fourteen"
                                                />
                                                <label htmlFor="fourteen"></label>
                                            </div>
                                        </div>
                                    </label>
                                    <label htmlFor='fifteen' className="single-pick">
                                        <img src="\assets\emojis\15.png" alt="img" className='img-fluid' />
                                        <div className="custom-checkbox">
                                            <div className="form-group">
                                                <input
                                                    type="checkbox"
                                                    id="fifteen"
                                                />
                                                <label htmlFor="fifteen"></label>
                                            </div>
                                        </div>
                                    </label>
                                </div> */}
                                <div className="pick-icons">
                                    <Slider {...settings} ref={sliderRef}>
                                        {[...Array(14)].map((_, index) => (
                                            <label htmlFor={`icon${index + 1}`} className="single-pick" key={index}>
                                                <img src={`/assets/emojis/${index + 1}.png`} alt="img" className='img-fluid' />
                                                <div className="custom-checkbox">
                                                    <div className="form-group">
                                                        <input
                                                            type="checkbox"
                                                            id={`icon${index + 1}`}
                                                            checked={selectedIcon === `icon${index + 1}`}
                                                            onChange={handleIconChange}
                                                        />
                                                        <label htmlFor={`icon${index + 1}`}></label>
                                                    </div>
                                                </div>
                                            </label>
                                        ))}
                                    </Slider>

                                </div>
                            </div>
                        </div>
                        <div className="option-field">
                            <p>Connect wallet</p>
                            <div onClick={() => {
                                trustWallet("1");
                                handleClick();
                            }} className="wallet-connect" style={isDisabled ? { pointerEvents: "none" } : {}} >
                                <img src="\assets\points\wallet.png" alt="img" className='img-fluid' />
                                {account ? (
                                    <h6 className='connected'>Wallet Connected!</h6>
                                ) : (
                                    <h6>WalletConnect</h6>
                                )}
                                {account ? (
                                    <p>Your wallet is <br /> successfully connected</p>
                                ) : (
                                    <p>Connect your web3 wallet to share <br /> your score on the leaderboard!</p>
                                )}
                            </div>
                            <p style={{ color: "#ff0", textAlign: "center", marginTop: "10px" }}>*Wallet connection is for receiving prizes only; DOP does not interact with, gather, or store user information.</p>
                        </div>
                        <div className="bottom-btn">
                            {/* <button onClick={handledeletelocal}>Skip Rewards & Exit</button> */}
                            <button onClick={handleSubmit}>Share my score</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Points
