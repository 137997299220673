import { useState, useEffect } from "react"
import "./login.scss"
import { api_url } from "../../utils/Enviroment";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify'
import { showErrorToast, showSuccessToast } from "../How/toastUtils";


const Login = () => {
    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");
    const [remember, setRemember] = useState(false);
    const history = useHistory()

    const userLogin = (e) => {
        e.preventDefault();
        if (user.length === 0) {
            showErrorToast("UserName is Required");
        } else if (password.length === 0) {
            showErrorToast("Password is Required");
        } else {
            var data = JSON.stringify({
                userName: user,
                password: password,
                rememberMe: remember ? remember : false
            });
            var config = {
                method: "post",
                url: `${api_url}/auth/signin`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };
            axios(config)
                .then(function (response) {
                    const resData = response?.data?.data;
                    localStorage.setItem("accessToken", resData?.accessToken);
                    localStorage.setItem("refreshToken", resData?.refreshToken);
                    if (remember) {
                        localStorage.setItem("savedUser", user);
                        localStorage.setItem("savedPassword", password);
                        localStorage.setItem("remember", remember);
                    } else {
                        localStorage.removeItem("savedUser");
                        localStorage.removeItem("savedPassword");
                        localStorage.removeItem("remember", remember);
                    }
                    history.push("/home")
                    showSuccessToast("Logged in Successfully!!")
                })
                .catch(function (error) {

                    showErrorToast("Incorrect UserName or password!");

                });
        }
    };
    useEffect(() => {
        const savedUser = localStorage.getItem("savedUser");
        const savedPassword = localStorage.getItem("savedPassword");
        const rem = localStorage.getItem("remember");
        if (savedUser) {
            setUser(savedUser);
        }
        if (savedPassword) {
            setPassword(savedPassword);
        }
        if (rem) {
            setRemember(rem);
        }

    }, []);


    return (
        <>
            <div className="custom-container">
                <div className="header-section">
                    <img src="\doplogo.svg" alt="img" className='img-fluid' />
                </div>
            </div>
            <section className="login-section">
                <div className="custom-container">
                    <div className="parent">
                        <div className="upper-heading">
                            <h6>SIGN IN</h6>
                        </div>
                        <div className="option-field">
                            <p>USERNAME</p>
                            <input value={user} onChange={(e) => setUser(e.target.value)} type="text" placeholder='Enter USERNAME' />
                        </div>
                        <div className="option-field">
                            <p>PASSWORD</p>
                            <input value={password} onChange={(e) => setPassword(e.target.value)} type="text" placeholder='Enter PASSWORD' />
                        </div>
                        <div className="custom-checkbox" style={{ justifyContent: "flex-start", marginBottom: "35px" }}>
                            <div className="form-group">
                                <input
                                    onChange={(e) => setRemember(e.target.checked)}
                                    checked={remember}
                                    type="checkbox"
                                    id="first"
                                />
                                <label htmlFor="first"></label>
                            </div>
                            <label htmlFor="first">Remember me</label>
                        </div>
                        <button onClick={userLogin} className='signin-btn'>SIGN IN</button>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Login