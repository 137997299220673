import { React } from "react";

function ListingPageComponent({ onScroll, listInnerRef, userList }) {
    return (
        <div>
            <div
                onScroll={onScroll}
                ref={listInnerRef}
            >
                {userList.map((item, index) => {
                    return (                 
                            <div key={item._id || index} className="single-leader">
                                <div className="left-side">
                                    <p>{index + 1}</p>
                                    <div className="twice">
                                        <img
                                            src={item?.icon || "/assets/emojis/1.png"}
                                            alt="img"
                                            className="img-fluid"
                                            width={40}
                                            height={40}
                                        />
                                        <h6>{item?.name}</h6>
                                    </div>
                                </div>
                                <div className="right-side">
                                    <h6>Score: {item?.points}</h6>
                                </div>
                            </div>                
                    );
                })}
            </div>
        </div>
    );
}

export default ListingPageComponent;
