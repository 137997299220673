
import React, { useEffect, useState, useRef } from "react";
import "./banner.scss";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Navbar from "../header/Navbar";
import { api_url } from "../../../utils/Enviroment";
import axios from "axios";
import ListingPageComponent from "../../../ListingPageComponent";
import { useHistory } from 'react-router-dom';
import { showErrorToast } from "../../How/toastUtils";

const AfterBanner = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const history = useHistory()
    const listInnerRef = useRef();
    const [currPage, setCurrPage] = useState(1);
    const [userList, setUserList] = useState([]);
    const [lastList, setLastList] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${api_url}/users?limit=100&offset=${currPage}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                    }
                });
                console.log(response.data.data.users, "Response Data");
                if (!response.data.data.users.length) {
                    setLastList(true);
                    return;
                }
                setUserList(prevUserList => [...prevUserList, ...response.data.data.users]);
                console.log([...userList, ...response.data.data.users], 'Updated User List');
            } catch (error) {
                console.error("Error fetching data:", error);
                if (error.response && error.response.status === 401) {
                    try {
                        const refreshToken = localStorage.getItem('refreshToken');
                        const refreshResponse = await axios.post(`${api_url}/auth/refresh-token`, { refreshToken: refreshToken });
                        const newAccessToken = refreshResponse?.data?.data?.accessToken;
                        localStorage.setItem('accessToken', newAccessToken);
                        const retryResponse = await axios.get(`${api_url}/users?limit=100&offset=${currPage}`, {
                            headers: {
                                Authorization: `Bearer ${newAccessToken}`
                            }
                        });
                        if (!retryResponse?.data?.data?.users?.length) {
                            setLastList(true);
                            return;
                        }
                        setUserList(prevUserList => [...prevUserList, ...retryResponse.data.data.users]);
                    } catch (refreshError) {
                        console.error("Error refreshing token:", refreshError);
                        localStorage.removeItem('savedPassword')
                        localStorage.removeItem('savedUser')
                        localStorage.removeItem('remember');
                        localStorage.removeItem('actionPayload');
                        history.push('/');
                        showErrorToast("Session expired. Please log in again.");
                    }
                } else {
                    console.error("Error fetching data:", error);
                }
            }
        };

        if (!lastList) {
            fetchData();
        }
    }, [currPage, lastList]);

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 5) {
                setCurrPage(prevPage => prevPage + 1);
            }
        }
    };

    useEffect(() => {
        const refCurrent = listInnerRef.current;
        if (refCurrent) {
            refCurrent.addEventListener('scroll', onScroll);
            return () => refCurrent.removeEventListener('scroll', onScroll);
        }
    }, [listInnerRef]);


    return (
        <>
            <div className="wrapper-afterbanner">
                <Navbar />
                <section className="main-banner after-banner">
                    <img src="\assets\banner\banner-bg.png" alt="img" className="img-fluid banner-bg" />
                    <div className="leaderboard-container">
                        <div className="parent-after">
                            <div className="main-content">
                                <h4>Welcome to the leaderboard</h4>
                                <p>Your prize will be airdropped to <br /> your wallet today by the end of the day <br />
                                    Thank you for playing!</p>
                                <div className="leaderboard">
                                    <div className="parent">
                                        {/* <span className="blurryeffect"><h6>Top 3 winners coming soon! <br /> Stay tuned!</h6></span> */}
                                        <div className="single-item transform-minus">
                                            <div className="main-img">
                                                <img
                                                    src={userList[1]?.icon || `/assets/emojis/2.png`}
                                                    alt="img"
                                                    className="img-fluid main-emoji"
                                                />
                                                <img
                                                    src="/assets/banner/two.svg"
                                                    alt="img"
                                                    className="img-fluid common-center-img"
                                                />
                                            </div>
                                            <div className="text">
                                                <h6>{userList[1]?.name}</h6>
                                                <p>Score: {userList[1]?.points}</p>
                                            </div>
                                        </div>
                                        <div className="single-item center-main">
                                            <div className="main-img">
                                                <img
                                                    src={userList[0]?.icon || `/assets/emojis/1.png`}
                                                    alt="img"
                                                    className="img-fluid main-emoji"
                                                />
                                                <img
                                                    src="/assets/banner/one.svg"
                                                    alt="img"
                                                    className="img-fluid common-center-img"
                                                />
                                            </div>
                                            <div className="text">
                                                <h6>{userList[0]?.name}</h6>
                                                <p>Score: {userList[0]?.points}</p>
                                            </div>
                                        </div>
                                        <div className="single-item transform-minus">
                                            <div className="main-img">
                                                <img
                                                    src={userList[2]?.icon || `/assets/emojis/3.png`}
                                                    alt="img"
                                                    className="img-fluid main-emoji"
                                                />
                                                <img
                                                    src="/assets/banner/three.svg"
                                                    alt="img"
                                                    className="img-fluid common-center-img"
                                                />
                                            </div>
                                            <div className="text">
                                                <h6>{userList[2]?.name}</h6>
                                                <p>Score: {userList[2]?.points}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bottom-logo">
                                    <img src="\large-logo.svg" alt="img" className="img-fluid" />
                                    <h4>Your data, your choice</h4>
                                </div>
                            </div>
                            {/* <div className="leaderboard-data">
                                {users.map((item, index) => (
                                    <div key={item._id} className="single-leader">
                                        <div className="left-side">
                                            <p>{index + 1}</p>
                                            <div className="twice">
                                                {console.log(item?.icon, 'fredfr')}
                                                <img
                                                    src={item?.icon || "/assets/emojis/1.png"}
                                                    alt="img"
                                                    className="img-fluid"
                                                    width={40}
                                                    height={40}
                                                />
                                                <h6>{item?.name}</h6>
                                            </div>
                                        </div>
                                        <div className="right-side">
                                            <h6>Score: {item?.points}</h6>
                                        </div>
                                    </div>
                                ))}
                                {loading && <p className="text-white">Loading more users...</p>}
                                {!hasMore && <p className="text-white">No more users to load.</p>}                         
                            </div> */}
                            <div className="leaderboard-data">
                                <ListingPageComponent
                                    onScroll={onScroll}
                                    listInnerRef={listInnerRef}
                                    userList={userList}
                                />
                            </div>
                        </div>
                        <div className="bottom-logo d-none blockformobile">
                            <img src="\large-logo.svg" alt="img" className="img-fluid" />
                            <h4>Your data, your choice</h4>
                        </div>



                    </div>
                </section>
            </div>
        </>
    );
};

export default AfterBanner;
