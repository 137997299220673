import { Link } from "react-router-dom";
import "./navbar.scss";

const Navbar = () => {
  return (
    <>
      <section className="main-navbar">
        <div className="custom-container">
          <nav class="navbar navbar-expand-xl">
            <Link class="navbar-brand" to="/home"><img src="\doplogo.svg" alt="img" className="img-fluid" /></Link>
              <Link to="/leaderboard" className="btn-launch">
                <h6>view Leaderboard</h6>
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                  <path d="M9.20831 6.16671L12.0416 9.00004L9.20831 11.8334M4.95831 6.16671L7.79165 9.00004L4.95831 11.8334" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </Link>
              <Link to="/home" className="btn-launch d-none btn-backhome">
                <h6>home</h6>
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                  <path d="M9.20831 6.16671L12.0416 9.00004L9.20831 11.8334M4.95831 6.16671L7.79165 9.00004L4.95831 11.8334" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </Link>
          </nav>
        </div>
      </section>
    </>
  );
};

export default Navbar;